
import { useCookies } from 'react-cookie';
import { useContext, useState } from "react";
import { useAuth } from "@/common/hooks/auth";

import Link from "next/link";
import Image from "next/image";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useRouter } from "next/router";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';

import AppContext from "@/context/AppContext";
import homeIcon from '@/assets/images/home.svg';
import helpIcon from '@/assets/images/help.svg';
import teamIcon from '@/assets/images/team.svg';
import logoutIcon from '@/assets/images/logout.svg';
import historyIcon from '@/assets/images/history.svg';
import cleversoIcon from '@/assets/images/cleverso.svg';
import accountIcon from '@/assets/images/account.svg';
import { isMobile } from "react-device-detect";
import UserContext from "@/context/UserContext";
import ApplicationLogo from "../ApplicationLogo/ApplicationLogo";

const drawerWidth = 240;

function EnterpriseSidebar(props) {
  const usercontext = useContext(UserContext);
  const isTeamAdmin = usercontext.user?.team_admin;
  const [cookies, setCookie] = useCookies(['cookie-name']);

  function handleClick(item) {
    if (item.disabled) {
      return;
    }
    const route = item.href;
    setCookie('route', route);
    if (route === 'logout') {
      logout();
    }
    else if (route === 'help') {
      window.location.href = 'https://tarjamagroup.atlassian.net/servicedesk/customer/portal/5';
    } else {
      setactiveRoute(route);
      props.parentFn(route);
    }
  }
  const router = useRouter();
  const [activeRoute, setactiveRoute] = useState(cookies.route || 'home');
  const { logout } = useAuth();
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const menuItems = [
    { text: t.Home, icon: homeIcon, href: 'home', disabled: false, link: '/enterprise/home' },
    { text: t.Team, icon: teamIcon, href: 'team', disabled: !isTeamAdmin, link: '/enterprise/team' },
    { text: t.History, icon: historyIcon, href: 'history', disabled: false, link: '/enterprise/history' },
    { text: t.Cs, icon: cleversoIcon, href: 'cs', disabled: false, link: 'https://app.cleverso.com/' }
  ]

  const footerMenuItems = [
    { text: t.Account, icon: accountIcon, href: 'accountInfo', link: '/enterprise/accountInfo' },
    { text: t.Help, icon: helpIcon, href: 'help', link: '' },
    { text: t.Logout, icon: logoutIcon, href: 'logout', link: '' },
  ]
  const drawer = (
    <div>
      <Toolbar className="flex justify-between">
        <Link href="/enterprise/home">
          <a>
            <ApplicationLogo className="block h-10 w-auto fill-current text-gray-600" />
          </a>
        </Link>
      </Toolbar>
      <Divider />
      <List>
        {menuItems.map((menuItem, index) => (
          <ListItem key={index} className="py-0">
            {menuItem.href === 'cs' ? (
              <a href={menuItem.link} target="_blank" rel="noopener noreferrer" className="w-full">
                <ListItemButton
                  className={`${activeRoute === menuItem.href ? 'bg-red10 rounded' : ''} ${menuItem.disabled ? 'text-grey200 cursor-auto' : 'cursor-pointer'}`}>
                  <ListItemIcon>
                    <Image src={menuItem.icon} width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText primary={menuItem.text} className={`${isArabic ? 'text-right' : 'text-left'}`} />
                </ListItemButton>
              </a>
            ) : (
              <Link href={menuItem.link}>
                <a className="w-full">
                  <ListItemButton
                    onClick={() => handleClick(menuItem)}
                    className={`${activeRoute === menuItem.href ? 'bg-red10 rounded' : ''} ${menuItem.disabled ? 'text-grey200 cursor-auto' : 'cursor-pointer'}`}>
                    <ListItemIcon>
                      <Image src={menuItem.icon} width={20} height={20} />
                    </ListItemIcon>
                    <ListItemText primary={menuItem.text} className={`${isArabic ? 'text-right' : 'text-left'}`} />
                  </ListItemButton>
                </a>
              </Link>
            )}
          </ListItem>
        ))}
      </List>
      <List className='!absolute bottom-0 w-full'>
        {footerMenuItems.map((menuItem, index) => (
          <ListItem key={index} className="py-0">
            <Link href={menuItem.link}>
              <a className="w-full">
                <ListItemButton onClick={() => handleClick(menuItem)} key={index} className={`${activeRoute === menuItem.href ? 'bg-red10 rounded' : ''}`}>
                  <ListItemIcon>
                    <Image src={menuItem.icon} width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText primary={menuItem.text} className={`${isArabic ? 'text-right' : 'text-left'}`} />
                </ListItemButton>
              </a>
            </Link>
          </ListItem>
        ))}
      </List>
    </div >
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          className="hidden md:block"
          variant="permanent"
          sx={{
            display: 'block',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          anchor={isArabic ? 'right' : 'left'}
        >
          {drawer}
        </Drawer>
        {props.isMobileMenuOpen &&
          <Drawer
            className="block md:hidden"
            variant="permanent"
            sx={{
              display: 'block',
              zIndex: '1',
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', height: '60%' },
            }}
            anchor={'bottom'}
          >
            {drawer}
          </Drawer>
        }
      </Box>
    </Box>
  );
}

export default EnterpriseSidebar;